import ContainerMain from "src/pages/common/ContainerMain"
import image1 from "images/Home/OurServices/OurServices1.png"
import image2 from "images/Home/OurServices/OurServices2.png"
import image3 from "images/Home/OurServices/home-service-3.png"
import image4 from "images/Home/OurServices/home-service-4.png"
import "./OurServices.scss"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import { useFormatter } from "../../../helpers/i18n"

export default function OurServices() {
  const { __, Link } = useFormatter()
  return (
    <>
      <div className="fSTLvrgvKt">
        <ContainerMain>
          <div className="BlpEJrISXN">
            <div className="section-title LHchJgDqrk">
              {__({ defaultMessage: "Our Services" })}
            </div>
            <div className="NPWcBHPdBy">
              <div className="XMVnTTiQVt">
                <div className="MljFutRpYK">
                  <ImageZoomEffect
                    alt=""
                    className="GvMWIYQXCn"
                    loading="lazy"
                    src={image1}
                  />
                </div>
                <div className="BzTTmqDpqY">
                  {__({ defaultMessage: "E-marketing Management" })}
                </div>
                <div className="kyTTgOGgnS">
                  <Link
                    to="/service-marketing"
                    className="btn-learn-more background-white"
                  >
                    {__({ defaultMessage: "Learn more" })}
                  </Link>
                </div>
              </div>
              <div className="XMVnTTiQVt">
                <div className="MljFutRpYK">
                  <ImageZoomEffect
                    alt=""
                    className="GvMWIYQXCn"
                    loading="lazy"
                    src={image2}
                  />
                </div>
                <div className="BzTTmqDpqY">
                  {__({ defaultMessage: "E-store Management" })}
                </div>
                <div className="kyTTgOGgnS">
                  <Link
                    to="/service-store"
                    className="btn-learn-more background-white"
                  >
                    {__({ defaultMessage: "Learn more" })}
                  </Link>
                </div>
              </div>
              <div className="XMVnTTiQVt">
                <div className="MljFutRpYK">
                  <ImageZoomEffect
                    alt=""
                    className="GvMWIYQXCn"
                    loading="lazy"
                    src={image3}
                  />
                </div>
                <div className="BzTTmqDpqY">
                  {__({ defaultMessage: "Shopper Engagement" })}
                </div>
                <div className="kyTTgOGgnS">
                  <Link
                    to="/service-engagement"
                    className="btn-learn-more background-white"
                  >
                    {__({ defaultMessage: "Learn more" })}
                  </Link>
                </div>
              </div>
              <div className="XMVnTTiQVt">
                <div className="MljFutRpYK ">
                  <ImageZoomEffect
                    alt=""
                    className="GvMWIYQXCn"
                    loading="lazy"
                    src={image4}
                  />
                </div>
                <div className="BzTTmqDpqY">
                  {__({
                    defaultMessage: "Omni-channel Logistics & Fulfilment",
                  })}
                </div>
                <div className="kyTTgOGgnS">
                  <Link
                    to="/service-logistics"
                    className="btn-learn-more background-white"
                  >
                    {__({ defaultMessage: "Learn more" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
